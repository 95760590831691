/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react'

const useGTMDataLayer = () => {
  const pushToDataLayer = useCallback((event: any, data = {}) => {
    if (typeof window !== 'undefined' && window?.dataLayer) {
      window?.dataLayer.push({
        event,
        ...data,
      })
    }
  }, [])
  const pushPurchaseEvent = useCallback(
    (transactionId: any, value: any, items: any) => {
      pushToDataLayer('purchase', {
        transaction_id: transactionId,
        value: value,
        currency: 'VND',
        items: items,
      })
    },
    [pushToDataLayer]
  )
  const pushAddToCartEvent = useCallback(
    (
      itemId: any,
      itemName: any,
      price: number,
      discount: number,
      quantity = 1,
      currency = 'VND'
    ) => {
      pushToDataLayer('add_to_cart', {
        currency,
        value: price * quantity,
        items: [
          {
            item_id: itemId,
            item_name: itemName,
            discount,
            price,
            quantity,
          },
        ],
      })
    },
    [pushToDataLayer]
  )
  const pushCancelOrderEvent = useCallback(
    (orderId: any, value: any, currency = 'VND', reason: any) => {
      pushToDataLayer('cancel_order', {
        order_id: orderId,
        value: value,
        currency: currency,
        reason: reason,
      })
    },
    [pushToDataLayer]
  )

  const pushApplyVoucherEvent = useCallback(
    (voucherCode: any, discountValue: any, currency = 'VND') => {
      pushToDataLayer('apply_voucher', {
        voucher_code: voucherCode,
        discount_value: discountValue,
        currency: currency,
      })
    },
    [pushToDataLayer]
  )

  return {
    pushToDataLayer,
    pushPurchaseEvent,
    pushAddToCartEvent,
    pushCancelOrderEvent,
    pushApplyVoucherEvent,
  }
}

export default useGTMDataLayer
